@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

.logo {
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.home-container {
  display: flex;
  flex-direction: column;
}
.content-container {
  display: flex;
}
.content-container-user {
  display: flex;
}
.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  flex: 1;
  padding-left: 161px;
  padding-top: 33px;
}
.topbar-container {
  width: 100%;
  background: #1b1b1e;

  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}

.top-bar-button {
  width: 96px;
  height: 44px;
  border-radius: 200px;
  gap: 8px;
  border: 1px solid #75777F;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  background-color: white;
  color: #0059C7;
  background: transparent;
}
.error-message {
  color: red;
  font-size: 12px;
  margin-top: -15px;
  margin-left:25px;
  text-align: start;
}

.error-message-reset{
  color: red;
  font-size: 12px;
  margin-top: -58px;
  margin-left:25px;
  text-align: start;
}

.error-message-set-password{
  color: red;
  font-size: 12px;
  margin-top: -15px;
  margin-left:25px;
  text-align: start;
}
.dropdown-show {
  padding: 6px 8px 6px 12px;
  border-radius: 8px;
  gap: 8px;
  color: #75777f;
  border: 1px solid #75777f;
  display: inline-block;
}

.dropdown-btn {
  text-decoration: none;
  color: white;
}
.avatar-container {
  width: 40px;
  height: 40px;
  background: #0059c7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.csv-link{
  text-decoration: none;
  color: #0059c7;
}

.custom-avatar {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: center;
  color: white;
}

.wrapper-all {
  margin: 80px auto;
  width: 400px;
  height: 308px;
  top: 160px;
  left: 520px;
  border-radius: 12px;
  gap: 16px;
  background: #fdfbff;
  box-shadow: 0px 4px 4px 0px #0000004d;
  box-shadow: 0px 8px 12px 6px #00000026;
}

.wrapper-all-set-password{
  margin: 80px auto;
  width: 400px;
  height: 400px;
  top: 160px;
  left: 520px;
  border-radius: 12px;
  gap: 16px;
  background: #fdfbff;
  box-shadow: 0px 4px 4px 0px #0000004d;
  box-shadow: 0px 8px 12px 6px #00000026;
}
.password-reset-title {
  width: 117px;
  height: 24;
}
.mail-show {
  color: black !important;
}

.material-icon {
  color: #6d6060;
  margin-right: 2%;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsive-button {
  padding: 10px 20px;
  font-size: 16px;
}

.verification-code-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .key-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .digit-input {
    width: 50px;
    text-align: center;
  }

  input[type="text"] {
    width: 100%;
    max-width: 50px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
  }
}
.title-body-wrapper {
  width: 250px;
  height: 52px;
  gap: 8px;
}
.first-half {
  width: 400px;
  height: 148px;
  padding: 16px 0px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.title {
  font-size: 16px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  color: #1b1b1e;
}

.title-body {
  width: 255px;
  height: 20px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #1b1b1e;
}

.field {
  display: flex;
  align-items: center;
  width: 360px;
  height: 56px;
  padding: 0px 14px;
  border-radius: 4px;
  border: 1px solid #75777f;
  margin-bottom: 20px;
  margin-left: 20px;

  input {
    flex: 1;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    outline: none;
    border: none;
    background: none;
    padding: 10px 15px 10px 10px;
  }
}

.signin-button-common {
  width: 93px;
  height: 40px;
  padding: 10px 24px;
  border-radius: 100px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  cursor: pointer;

  background: #0059c7;
}

.button-common {
  width: 106px;
  height: 40px;
  padding: 10px 24px;
  border-radius: 100px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  cursor: pointer;

  background: #0059c7;
}
.button {
  // font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #fff;
  cursor: pointer;
}

.button-container-password {
  display: flex;
  justify-content: space-between;
}
.button-container-set-password {
  display: flex;
  justify-content: end;
}
.back-button {
  width: 56px;
  height: 40px;

  border-radius: 100px;
  border: none;
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #0059c7;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  cursor: pointer;
  background: none;
  margin-left: 8px;
}
.forgot-button {
  width: 255px;
  height: 40px;

  border-radius: 100px;
  border: none;

  justify-content: left;
  align-items: left;
  gap: 8px;
  color: #0059c7;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: left;
  cursor: pointer;
  background: none;
}
.links {
  text-decoration: none;
}
.continue-button {
  width: 106px;
  height: 40px;
  padding: 10px 24px;
  border-radius: 100px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  cursor: pointer;
  margin-right: 18px;

  background: #0059c7;
}
.continue-button-set-password {
  width: 106px;
  height: 40px;
  padding: 10px 24px;
  border-radius: 100px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  cursor: pointer;
  margin-right: 18px;

  background: #0059c7;
}

@media (max-width: 380px) {
  .wrapper {
    padding: 40px 15px 15px 15px;
  }
}

@media (max-width: 425px) {
  .wrapper {
    max-width: 80%;
  }
}

@media (max-width: 480px) {
  .button-container {
    flex-direction: column;
    height: auto;
  }
}
@media (max-width: 600px) {
  .wrapper-all {
    width: 90%;
    max-width: 400px;
    margin: 40px auto;
  }

  .first-half {
    width: 100%;
    padding: 16px 0px;
  }

  .field {
    width: 88%;
  }

  .bottom {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .button-common {
    width: 88%;
  }

  .forgot-button {
    width: 100%;
    text-align: center;
  }

  .responsive-button {
    width: 100%;
    text-align: center;
  }

  .button-container-password {
    flex-direction: row;
    gap: 16px;
  }
}

@media (max-width: 500px) {
  .material-icon {
    margin-left: -45px;
  }
}


@media (max-width: 500px) {
  .logo{
    margin-top: -20px;
  }
}



@media (max-width: 500px) {
  .title{
    margin-top: -20px;
  }
}



@media (max-width: 500px) {
  .field{
    margin-top: -36px;
  }
}


@media (max-width: 500px) {
  .error-message{
    margin-top: -15px;
  }

  .error-message-set-password{
    margin-top: -15px;
  }
}

@media (max-width: 1280px) {
  .main-content {
    padding-left: 115px;
  }
}
.sidebar {
  width: 320px;
  background-color: #f0f2fc;
  height: 100vh;
  position: fixed;
  top: 64px;
  z-index: 100;
  box-shadow: 0px 1px 3px 1px #00000026;
  box-shadow: 0px 1px 2px 0px #0000004d;

  padding: 12px;

  .sidebar-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 100px;
    gap: 12px;
    outline: none;
    width: 296px;
    height: 56px;
    text-decoration: none;
    &:hover {
      background-color: #dbe2f9;
      text-decoration: none;
    }
  }

  .sidebar-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 100px;
    gap: 12px;
    background-color: transparent;
    border: none;
    outline: none;
    width: 296px;
    height: 56px;

    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    color: #44464e;
    &:hover {
      background-color: transparent;
    }

    .sidebar-icon {
      width: 24px;
      height: 24px;
    }
  }

  .active {
    background: #dbe2f9;
  }
}

.totalModeration {
  margin-left: 125px;
}

@media (max-width: 1280px) {
  .sidebar {
    width: 230px;
  }
  .sidebar .sidebar-link {
    width: 200px;
  }
  .totalModeration {
    margin-left: 30px;
  }
}

@media (max-width: 1150px) {
  .sidebar {
    height: 1065px;
  }
}

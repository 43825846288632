.delete-button {
	width: 40px;
	height: 40px;
	padding-left: 30px !important;
	padding-top: 20px !important;
}

.save-button {
	width: 208px;
	height: 44px;
	padding: 10px, 24px, 10px, 24px;
	border-radius: 100px !important;
	gap: 10px;
	border: 1px solid #75777f;
	box-shadow: none;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.10000000149011612px;
	text-align: center;
	background-color: #0059c7 !important;
	text-decoration: none !important;
	&:hover {
		background-color: white;
		color: "#0059C7";
	}
}

.add-button {
	width: 208px;
	height: 44px;
	border-radius: 100px !important;
	text-align: center;
	margin-left: 44px !important;
	color: #0059c7 !important;
	background-color: #ffffff !important ;
	text-decoration: none !important;
	&:hover {
		background-color: white;
		color: "#0059C7";
	}
}

.button-box {
	display: flex;
	width: 50%;
	margin-top: 30px;
	margin-left: 30px;
	margin-bottom: 15px;
}

@media (max-width: 768px) {
	.button-box {
		display: flex;
		width: 100% !important;
		margin-top: 50px;
	}
	.add-button {
		margin-left: 25px !important;
	}
}

@media (min-width: 768px) and (max-width: 1024px) {
	.save-button {
		width: 140px;
		height: 35px;
	}
	.add-button {
		width: 140px;
		height: 35px;
	}
	.button-box {
		display: flex;
		width: 60%;
		margin-top: 50px;
	}
}

.main-card {
  width: 100%;
  height: 100vh;
}

.card-wrapper {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 24px 16px;
  border-radius: 8px;
  gap: 32px;
  background: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);

  .card-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    line-height: 36px;
  }
  .profile {
    margin-top: 32px;
  }
  .remove-button-box {
    padding: 10px, 24px, 10px, 24px !important;
  }
  .remove-button {
    width: 160px;
    height: 40px;
    padding: 10px, 24px, 10px, 24px;
    border-radius: 100px;
    border: 1px solid #75777f;
    box-shadow: none;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    color: #0059c7;
    background-color: #ffffff;
    text-decoration: none;
    text-transform: capitalize;
    &:hover {
      background-color: white;
      color: "#0059C7";
    }
  }

  .avatar {
    width: 160px;
    height: 160px;
    color: #ffffff;
  }
  .edit-button {
    border-radius: 100px;
    border: 1px solid #75777f;
    width: 73px;
    height: 40px;
    padding: 10px, 24px, 10px, 24px;
    gap: 8px;
    color: #0059c7;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    text-transform: capitalize;
  }
  .deletebutton{
    border-radius: 100px;
    border: 1px solid #75777f;
    width: 73px;
    height: 40px;
    padding: 10px, 24px, 10px, 24px;
    gap: 8px;
    background-color: #0059c7;
    color:white;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    text-transform: capitalize;

  }
  .deletebutton:hover {
    background-color: #0059c7; /* Set to the same color as the button's background color */
  }
  .button-gap{
    gap: 6px;
    display: flex;
  }

  .save-button {
    border-radius: 100px;
    border: 1px solid #75777f;
    width: 73px;
    height: 40px;
    padding: 10px, 24px, 10px, 24px;
    gap: 8px;
    color: #ffffff;
    background-color: #0059c7;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    text-transform: capitalize;
  }
  .card-header-about {
    font-size: 28px;
    font-weight: 400;
  }

  .form {
    padding-top: 24px;
    padding-left: 16px;
    width: 30%;
  }

  .card-header-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    gap: 32px;
  }

  .card-header-data {
    height: 36px;
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .stats-row {
    width: 515px;
    height: 40px;
    padding: 8px 0px;
    border: 0px 0px 1px 0px;
    gap: 16px;
    color: #75777f;
    border-bottom: 1px solid #75777f;
  }

  .data-column {
    display: flex;
  }

  .data-title {
    width: 300px;
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    color: #000000;
  }

  .data-value {
    width: 200px;
    height: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #0059c7;
  }
}

// .content-container {
//   display: flex;
// }

// .main-content {
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   width: 100%;
// }

// .topbar-container {
//   width: 100%;
//   background: #1b1b1e;
//   font-family: Roboto;
//   font-size: 22px;
//   font-weight: 400;
//   line-height: 28px;
//   letter-spacing: 0em;
//   text-align: center;
// }

.onButton {
  border-radius: 100px;
  background-color: transparent;
  width: 110px;
  border: 1px solid blue;
  height: 24px;
  text-transform: none;
  margin-right: 10px;
  background-color: #0059c7;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onButton:hover {
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid black;
  width: 110px;
  border: 1px solid #75777f;
  height: 24px;
  text-transform: none;
  margin-right: 10px;
  background-color: #0059c7;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.offButton {
  border-radius: 100px;
  background-color: transparent;
  color: #0159c7;
  border: 1px solid black;
  width: 110px;
  border: 1px solid #75777f;
  height: 24px;
  text-transform: none;
  margin-right: 10px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.offButton:hover {
  border-radius: 10px;
  background-color: transparent;
  color: #0159c7;
  border: 1px solid black;
  width: 110px;
  border: 1px solid #75777f;
  height: 24px;
  text-transform: none;
  margin-right: 10px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

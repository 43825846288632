.custom-navbar {
  display: flex;
  width: 100%;
  height: 64px;
  justify-content: space-between;
  align-items: center;
  background-color: #d8e6f1;
  box-shadow: 0px 2px 6px 2px #00000026;
  box-shadow: 0px 1px 2px 0px #0000004d;
  padding: 8px, 16px, 8px, 16px;
  position: fixed;
  z-index: 100;
}

.navbar-custom-brand {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;

  align-items: center;
}

.nav-custom-link {
  margin-right: 16px;
  text-decoration: none;
}
.navbar-custom-text {
  width: 117px;
  height: 24px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
}
.right-nav {
  display: flex;
  align-items: center;
}

.left-nav {
  display: flex;
  align-items: center;
}
.navbar-text-uon {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-left: 20px;
}
.menu-button {
  width: 200px;
  height: 40px;
  gap: 8px;
}
.menu-button-style {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  margin-top: 5px;
  background-color: transparent;
  color: #42474e;
  margin-left: 27px;
  border: none;
}

@media (max-width: 767.98px) {
  .custom-navbar {
    padding: 0 8px;
  }
}


@media (max-width: 880px) {
  .custom-navbar {
    padding: 0 8px;
    width: 150vh;
  }
}





.table-container {
  width: 100%;
  height: 100%;
  margin-top: 10px;
  .btn-table {
    width: 129px;
    height: 40px;
    padding: 8px 12px;
    border: 0px 0px 1px 0px;
    border-bottom: 1px solid #e4e2e6;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    background-color: transparent;
    color: #e4e2e6;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

.btn-banned {
  width: 96px;
  height: 24px;
  padding: 10px 24px;
  border-radius: 100px;
  gap: 8px;
  color: #7c0303ab;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  &:hover {
    background-color: rgba(124, 3, 3, 0.2);
  }
}
.btn-filter {
  width: 102.21px;
  height: 40px;
  padding: 4px 6px;
  border-radius: 100px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #75777f;
  color: #0059c7;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  position: relative;
  top: -47px;
}

.btn-filter-col {
  display: flex;
  justify-content: end;
}

.tableHeaderStyle {
  color: black;
  font-weight: bold;
  width: 200px;
}

.MuiTablePagination-selectLabel {
  padding-top: 12px;
}

.MuiTablePagination-displayedRows {
  padding-top: 15px;
}

.MuiDataGrid-panel {
  padding-left: 1030px;
}

.MuiDataGrid-columnHeaders {
  background-color: #bdbdbd;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar
  .MuiTablePagination-actions {
  padding-top: -2px;
}

@media (max-width: 1440px) {
  .MuiDataGrid-panel {
    padding-left: 500px;
  }
}

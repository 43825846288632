.main-card {
  width: 100%;
}
.card-wrapper {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 24px 16px;
  border-radius: 8px;
  gap: 32px;
  background: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  overflow-x: hidden;

  .card-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    line-height: 36px;
  }

  .edit-button {
    border-radius: 100px;
    border: 1px solid #75777f;
    width: 73px;
    height: 40px;
    padding: 10px, 24px, 10px, 24px;
    gap: 8px;
    color: #0059c7;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
  }

  .save-button {
    border-radius: 100px;
    border: 1px solid #75777f;
    width: 73px;
    height: 40px;
    padding: 10px, 24px, 10px, 24px;
    gap: 8px;
    color: #ffffff;
    background-color: #0059c7;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
  }
  .card-header-about {
    font-size: 28px;
    font-weight: 400;
  }
  .form {
    padding-top: 24px;
    padding-left: 16px;
    width: 30%;
  }
}

@media (max-width: 900px) {
  .card-wrapper {
    .form {
      padding-top: 18px;
      padding-left: 0px;
      width: 100%;
    }
  }
}

@media (min-width: 900px) and (max-width: 1024px) {
  .card-wrapper {
    .form {
      padding-top: 18px;
      padding-left: 0px;
      width: 50%;
    }
  }
}

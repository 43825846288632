.page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 370px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);

  .style-404 {
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  }
  
  .page-not-found-text {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.10000000149011612px;
    }
}

.blur-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #575E71;

    backdrop-filter: blur(5px); 
    z-index: 1000; 
    display: flex;
    justify-content: center;
    align-items: center;
  }

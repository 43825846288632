.top-bar {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 64px;
  padding-left: 10px;
  background-color: #E9EEFB;
  box-shadow: 0px 2px 6px 2px #00000026;
  box-shadow: 0px 1px 2px 0px #0000004d;
}
.top-bar-heading {
  height: 28px;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  justify-content: start;
  color: #1b1b1e;
  width: 900px
}
.top-bar-button-link {
  text-decoration: none;
}
.top-bar-buttons {
  gap: 10px;
}
.top-bar-button-update {
  width: 150px;
}
.top-bar-button-add {
  margin-left: auto;
}
.addAdminBtn {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  width: 115px;
  height: 44px;
  padding: 10px, 24px, 10px, 24px;
  border-radius: 100px;
  gap: 10px;
  background: #0059c7;
  color: white;
  border: none;
}
.saveBtn {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  width: 83px;
  height: 44px;
  padding: 10px, 24px, 10px, 24px;
  border-radius: 100px;
  gap: 10px;
  background: #0059c7;
  color: white;
  border: none;
}


@media (max-width: 1600px) {
  .display-bar{
      margin-left: -50px;
  }
}

@media (max-width: 1200px) {
  .display-bar{
      margin-left: 4px;
  }
}

@media (max-width: 900px) {
  .display-bar{
      margin-left: -30px;
  }
}

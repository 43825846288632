.screen {
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.25);
}

.modal {
    /* Add styles to show the modal */
     
    justify-content: center;
    width: 512px;
    height: 184.12px;
    display: flex;
    top: 23rem;
    left: 46rem;
    padding: 0px 0px 20px 0px;
    gap: 24px;
    border-radius: 10px;
    opacity: 0px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
    background-color: white;
   z-index: 101;
    align-items: center;


}
.delete-title{
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    font-family: Roboto;
font-size: 28px;
font-weight: 400;
line-height: 36px;
text-align: center;
color:#000000;

gap: 0px;


}
.modal-buttons{
    display: flex;
    justify-content: center;
    gap: 1rem;
}
.edit-button-modal{
    border-radius: 100px;
    border: 1px solid #75777f;
    width: 106px;
    height: 44px;
    padding: 10px, 24px, 10px, 24px;
    gap: 8px;
    color: #0059c7;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    text-transform: capitalize;
}
.deletebutton-modal{
    border-radius: 100px;
    border: 1px solid #75777f;
    width: 106px;
    height: 44px;
    padding: 10px, 24px, 10px, 24px;
    gap: 8px;
    background-color: #0059c7;
    color:white;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    text-transform: capitalize;
}
